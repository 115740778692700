if (window.innerWidth >= 1500) {
  const aboutTimeline = gsap.timeline();

  aboutTimeline.from('#landscape_1_', 2, { y: 100 });
  aboutTimeline.add('mountains-and-hills');
  aboutTimeline.from('#hills', 1, { y: 50, opacity: 0 }, 'mountains-and-hills');
  aboutTimeline.from(
    '#mountains',
    1,
    { y: -100, opacity: 0 },
    'mountains-and-hills'
  );
  aboutTimeline.from('#snowflakes', 1, { opacity: 0 }, 'mountains-and-hills');
  aboutTimeline.from('#trees1', { y: 100, opacity: 0 });
  aboutTimeline.from('#trees2', { y: 100, opacity: 0 });
  aboutTimeline.from('.text', { y: 100, opacity: 0 });
  aboutTimeline.from('#flag', { opacity: 0 });

  const snowFlakeTimeline = gsap.timeline({ repeat: -1 });
  snowFlakeTimeline.to('#snowflakes', 2, {
    yPercent: 40,
    ease: 'none',
  });

  ScrollTrigger.create({
    animation: aboutTimeline,
    trigger: '.about-container',
    start: 'top top',
    end: '+=3000',
    scrub: true,
    pin: '.about-container',
  });
}
